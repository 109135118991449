// src/modules/auth/frontend/components/ResetPasswordForm.js

import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { resetPassword } from '../services/authApi';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object({
  password: Yup.string().required('Пароль обов\'язковий').min(6, 'Пароль має містити мінімум 6 символів'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Паролі мають збігатися')
    .required('Підтвердження пароля обов\'язкове')
});

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('');
  const token = searchParams.get('token');

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await resetPassword(token, values.password);
        setMessage('Пароль успішно змінено.');
      } catch (error) {
        setMessage('Сталася помилка. Спробуйте ще раз.');
      }
    }
  });

  const onClose = () => {
    navigate('/my');
  };

  return (
    <Container maxWidth="xs">
      <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </Box>
      <Typography variant="h6" gutterBottom>
        Встановлення нового пароля
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          id="password"
          name="password"
          label="Новий пароль"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <TextField
          fullWidth
          margin="normal"
          id="confirmPassword"
          name="confirmPassword"
          label="Підтвердіть пароль"
          type="password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
        />
        {message && <Typography color="primary">{message}</Typography>}
        <Button color="primary" variant="contained" fullWidth type="submit" sx={{ mt: 2 }}>
          Змінити пароль
        </Button>
      </form>
    </Container>
  );
};

export default ResetPasswordForm;
