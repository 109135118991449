import axios from 'axios';

//const API_URL = 'http://localhost:3001/api/google-sheets'; // Базовий URL API
const API_URL = 'https://apitools.rotaryd2232ukraine.org/api/google-sheets'; // Базовий URL API

export const fetchGoogleSheetData = async (sheetId, pageIndex = 0) => {
  try {
    const response = await axios.get(API_URL, {
      params: {
        sheet_id: sheetId,
        page_index: pageIndex,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Помилка при отриманні даних із Google Sheets:', error);
    throw new Error('Не вдалося отримати дані з Google Sheets');
  }
};
