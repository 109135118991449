export const coord = [
    { "city": "Alushta", "coordinates": [34.4123, 44.6766] },
    { "city": "Cherkasy", "coordinates": [32.0649, 49.4444] },
    { "city": "Chernivtsi", "coordinates": [25.9403, 48.2915] },
    { "city": "Dnipro", "coordinates": [35.0456, 48.4647] },
    { "city": "Donetsk", "coordinates": [37.8029, 48.0159] },
    { "city": "Ivano-Frankivsk", "coordinates": [24.7097, 48.9226] },
    { "city": "Izmail", "coordinates": [28.8374, 45.3493] },
    { "city": "Kharkiv", "coordinates": [36.2304, 49.9935] },
    { "city": "Kherson", "coordinates": [32.6178, 46.6354] },
    { "city": "Khmelnytskyi", "coordinates": [26.9855, 49.4216] },
    { "city": "Kobelyaki", "coordinates": [34.2019, 49.1447] }, // Додано Kobelyaki
    { "city": "Kolomyia", "coordinates": [25.0376, 48.5281] },
    { "city": "Kropyvnytskyi", "coordinates": [32.2629, 48.5079] },
    { "city": "Kyiv", "coordinates": [30.5234, 50.4501] },
    { "city": "Lutchk", "coordinates": [25.3469, 50.7472] }, // Альтернативний варіант назви Lutsk
    { "city": "Lutsk", "coordinates": [25.3469, 50.7472] },
    { "city": "Lviv", "coordinates": [24.0316, 49.8429] },
    { "city": "Mukachevo", "coordinates": [22.7178, 48.4422] },
    { "city": "Mykolaiv", "coordinates": [32.0027, 46.9750] },
    { "city": "Odesa", "coordinates": [30.7233, 46.4825] }, // Альтернативний варіант назви Odessa
    { "city": "Odessa", "coordinates": [30.7233, 46.4825] },
    { "city": "Poltava", "coordinates": [34.5514, 49.5883] },
    { "city": "Rakhiv", "coordinates": [24.2038, 48.0516] },
    { "city": "Rivne", "coordinates": [26.2516, 50.6199] },
    { "city": "Scherkasy", "coordinates": [32.0649, 49.4444] }, // Варіант з неправильним символом
    { "city": "Simferopol", "coordinates": [34.1086, 44.9521] },
    { "city": "Slavyansk", "coordinates": [37.6140, 48.8482] },
    { "city": "Ternopil", "coordinates": [25.5918, 49.5535] },
    { "city": "Truskavets", "coordinates": [23.5094, 49.2784] },
    { "city": "Uman", "coordinates": [30.2277, 48.7484] },
    { "city": "Uzhhorod", "coordinates": [22.2967, 48.6210] },
    { "city": "Vinnitsa", "coordinates": [28.4810, 49.2331] }, // Альтернативний варіант назви Vinnytsia
    { "city": "Vinnytsia", "coordinates": [28.4810, 49.2331] },
    { "city": "Zaporizhzhia", "coordinates": [35.1396, 47.8388] },
    { "city": "Zhytomyr", "coordinates": [28.6587, 50.2547] },
    { "city": "Zvenihorodka", "coordinates": [30.9674, 49.0727] }
];
