import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Typography, Tabs, Tab, Box, Button
} from '@mui/material';



const ClubsTableComp = ({ title, dbclubs_, show_columns, handleAddClick, handleRemoveClick }) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');

    // Отримуємо заголовки колонок з імен полів об'єкта
    const keys = dbclubs_.length > 0 ? Object.keys(dbclubs_[0]) : [];
  
    // Фільтруємо ключі, якщо show_columns визначено, і залишаємо тільки ті, що мають значення 'show'
    const filteredKeys = show_columns
      ? keys.filter(key => show_columns[key] === 'show')
      : keys;
  
    // Обробник зміни сортування
    const handleSortRequest = (key) => {
      const isAsc = orderBy === key && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(key);
    };
  
    // Функція для сортування
    const sortedClubs = dbclubs_.sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return order === 'asc' ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    });
  
    return (
      <Paper>
        <Typography variant="h6" component="div" sx={{ padding: 2 }}>
          {title}
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="clubs table">
            <TableHead>
              <TableRow>
                <TableCell key={-1}>№</TableCell>
                {filteredKeys.map((key) => (
                  <TableCell key={key}>
                    <TableSortLabel
                      active={orderBy === key}
                      direction={orderBy === key ? order : 'asc'}
                      onClick={() => handleSortRequest(key)}
                    >
                      {key}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell key="actions">Actions</TableCell> {/* Додаємо колонку для дій */}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedClubs.map((club, index) => (
                <TableRow key={index}>
                  <TableCell key={-1}>{index + 1}</TableCell> {/* Додав 1 до індексу для нумерації з 1 */}
                  {filteredKeys.map((key) => (
                    <TableCell key={key}>{club[key]}</TableCell>
                  ))}
                  <TableCell key="actions">
                    {club.sync_res === 'new' && (
                      <Button variant="contained" color="primary" onClick={() => handleAddClick(club)}>
                        Add
                      </Button>
                    )}
                    {club.sync_res === 'removed' && (
                      <Button variant="contained" color="primary" onClick={() => handleRemoveClick(club)}>
                        Remove
                      </Button>
                    )}
                    {JSON.stringify(club)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  export default ClubsTableComp;