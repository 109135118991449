import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

import './Pages.css';
import MainPage from "../components/Main";
import LandingBlock from '../components/LandingBlock';
import TableLineComponent from '../components/TableLineComponent';
import { getAllClubs } from '../api_sequelize/api_rclubs';

const breadcrumbs = ["Дистрикт 2232", "Ротарі Клуби"];

function excludeSatelliteClubs(clubs) {
    return clubs.filter(club => 
        !club.clubname.includes("Сателітний") && 
        !club.clubname.includes("Satellite")
    );
}

function filterByTypeClubs(clubs, clubindex) {
    const ctypes = [
        "rotary",
        "rotaract",
        "Rotary Satellite Club",
        "Interact Club"
    ];
    return clubs.filter(club => 
        club.clubcat === ctypes[clubindex]
    );
}

function filterRClubsByLang(rclubs_, checkedStateLang) {
    return rclubs_.filter(club => checkedStateLang[club.clublang]);
}

function filterRClubsByCategory(rclubs_, checkedState) {
    return rclubs_.filter(club => checkedState[club.clubcat]);
}

function sortByClubName(array) {
    return array.sort((a, b) => {
        const nameA = a.clubname.toLowerCase();
        const nameB = b.clubname.toLowerCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    });
}

export const tableTitles = {
    t_clubname: "Назва клубу",
    t_charterDate: "Дата реєстрації",
};

export const tableTitles2 = {
    clubname: "Назва клубу",
    t_riClubId: "ID клубу",
    clubcat: "Тип клубу",
};

const cTitles = [
    "Ротарі Клуби",
    "Ротаракт Клуби",
    "Ротарі Сателітні Клуби",
    "Інтеракт Клуби"
];

const AllClubsPage = ({ cindex }) => {
    const [rclubs, setRClubs] = useState([]);
    const [filteredRclubs, setFilteredRClubs] = useState([]);

    useEffect(() => {
        LoadData();
    }, []);

    const LoadData = async () => {
        try {
            const dbcl = await getAllClubs();
            const f2 = dbcl.filter(club => club.clubcat !== "interact");
            const f3 = f2.filter(club => !club.clubname.startsWith("Satellite "));
            const f4 = f3.filter(club => !club.clubname.startsWith("Сателітний "));
            const f1 = f4.filter(club => club.clublang !== "UKR");
            const f5 = filterByTypeClubs(f1, cindex);

            // Додаємо сортування по назві клубу
            const sortedClubs = sortByClubName(f5);

            setFilteredRClubs(sortedClubs);
        } catch (error) {
            console.error('Помилка при завантаженні даних:', error);
        }
    };

    return (
        <LandingBlock mode='light'>
            <Container>
                <Typography variant="h4" component="span" color="darkblue" style={{fontWeight: 'bold'}}>{cTitles[cindex]} </Typography>
                <Typography variant="h7" component="span" > (к-сть: {filteredRclubs ? filteredRclubs.length : 0}) </Typography>
                <Typography variant="h7">  на 24.01.2025</Typography>
                <TableLineComponent 
                    data={filteredRclubs}
                    tableTitles={tableTitles2}
                    addNumber
                />
            </Container>
        </LandingBlock>
    );
}

export default AllClubsPage;
