import React, { useEffect, useState, useRef } from 'react';
import MapGL, { Source, Layer, Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Box } from '@mui/material';

import { getAllClubs } from '.././../api_sequelize/api_rclubs';
import { coord } from './coord'; // Імпорт координат

const MAPBOX_TOKEN = 'pk.eyJ1IjoidHlrb2dtMiIsImEiOiJjbHp4eHN2ZXIwejkzMmtzMnd1NzlmbmFwIn0.uHLLDghqAjo34FqE1hmPxw';

const geojson = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        color: '#FF0000',
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          // Додайте правильні координати областей України
        ],
      },
    },
    // Додайте більше областей тут
  ],
};

const layerStyle = {
  id: 'regions',
  type: 'fill',
  paint: {
    'fill-color': ['get', 'color'],
    'fill-opacity': 0.9,
  },
};

const bounds = [
  [20.0, 43.0], // Південно-західна точка України
  [40.2, 52.4], // Північно-східна точка України
];

const markerSize = { width: 140, height: 70 }; // Розміри маркерів

const avoidMarkerOverlap = (markers, map, viewport, size = markerSize) => {
  if (!map) return markers;

  const maxIterations = 10; // Максимальна кількість ітерацій
  const scaleFactor = 1 / Math.pow(2, viewport.zoom - 5); // Чим більший zoom, тим менше зміщення
  const maxShift = size.width * scaleFactor;

  let iterations = 0;
  let hasOverlap = true;

  const pixelPositions = markers.map((marker) => ({
    ...marker,
    pixel: map.project(marker.coordinates),
  }));

  while (hasOverlap && iterations < maxIterations) {
    hasOverlap = false;

    for (let i = 0; i < pixelPositions.length; i++) {
      for (let j = i + 1; j < pixelPositions.length; j++) {
        const dx = pixelPositions[i].pixel.x - pixelPositions[j].pixel.x;
        const dy = pixelPositions[i].pixel.y - pixelPositions[j].pixel.y;

        if (Math.abs(dx) < size.width && Math.abs(dy) < size.height) {
          hasOverlap = true;

          const angle = Math.atan2(dy, dx);
          const shiftX = maxShift * Math.cos(angle) / 2; // Ділимо зміщення між двома маркерами
          const shiftY = maxShift * Math.sin(angle) / 2;

          pixelPositions[i].pixel.x += shiftX;
          pixelPositions[i].pixel.y += shiftY;

          pixelPositions[j].pixel.x -= shiftX;
          pixelPositions[j].pixel.y -= shiftY;
        }
      }
    }
    iterations++;
  }

  return pixelPositions.map((marker) => {
    const newCoordinates = map.unproject([marker.pixel.x, marker.pixel.y]);
    if (!newCoordinates || isNaN(newCoordinates.lng) || isNaN(newCoordinates.lat)) {
      console.warn(`Некоректні координати для маркера:`, marker);
      return null; // Ігноруємо некоректний маркер
    }
    return {
      ...marker,
      coordinates: [newCoordinates.lng, newCoordinates.lat],
    };
  }).filter(Boolean); // Видаляємо null-значення
};


const ClubsMapMapGL = ({ filterRType }) => {
  const [groupedClubs, setGroupedClubs] = useState([]);
  const [viewport, setViewport] = useState({
    latitude: 48.1794,
    longitude: 30.1656,
    zoom: 5.9,
  });
  const mapRef = useRef(null);

  useEffect(() => {
    LoadData();
  }, [filterRType]);

  const LoadData = async () => {
    try {
      const dbcl = await getAllClubs();
      let filteredClubs = dbcl
        .filter((club) => club.clubcat !== 'interact')
        .filter((club) => !club.clubname.startsWith('Satellite '))
        .filter((club) => !club.clubname.startsWith('Сателітний '))
        .filter((club) => club.clublang !== 'UKR');

      if (filterRType) {
        filteredClubs = filteredClubs.filter((club) => club.clubcat === filterRType);
      }

      const groupedByCity = {};

      filteredClubs.forEach((club) => {
        const cityNameLowerCase = club.clubcity.toLowerCase();
        const cityCoord = coord.find((c) => c.city.toLowerCase() === cityNameLowerCase);

        if (cityCoord) {
          const cityKey = cityCoord.city.toLowerCase();
          if (!groupedByCity[cityKey]) {
            groupedByCity[cityKey] = {
              city: cityCoord.city,
              coordinates: cityCoord.coordinates,
              count: 0,
            };
          }
          groupedByCity[cityKey].count += 1;
        }
      });

      setGroupedClubs(Object.values(groupedByCity));
    } catch (error) {
      console.error('Помилка при завантаженні даних:', error);
    }
  };

  const getClubWord = (count) => {
    if (count === 1) return 'клуб';
    if (count >= 2 && count <= 4) return 'клуби';
    return 'клубів';
  };

  return (
    <Box sx={{ height: '90vh', width: '100%' }}>
      {/* viewport.zoom={viewport&&viewport.zoom} */}
      <MapGL
        {...viewport}
        ref={mapRef}
        style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/mapbox/light-v10"
        mapboxAccessToken={MAPBOX_TOKEN}
        onMove={(evt) => setViewport(evt.viewState)}
        maxBounds={bounds} // Обмеження меж карти
        minZoom={5.55} // Мінімальний зум
        maxZoom={10} // Максимальний зум
      >
        {/* <Source id="regions" type="geojson" data={geojson}>
          <Layer {...layerStyle} />
        </Source> */}

        {mapRef.current &&
          avoidMarkerOverlap(groupedClubs, mapRef.current.getMap(), viewport).map((club, index) => (
            <Marker
              key={index}
              longitude={club.coordinates[0]}
              latitude={club.coordinates[1]}
            >
              <div
                style={{
                  textAlign: 'center',
                  color: 'black',
                  fontSize: '1.5em',
                  border: '1px solid orange',
                  padding: '2px',
                  backgroundColor: 'rgba(255, 255, 255, 0.72)', // Прозорий фон на 42%
                  borderRadius: '4px', // Радіус бордера
                  width: `${markerSize.width}px`,
                  height: `${markerSize.height}px`,
                  boxShadow: '2px 2px 5px rgba(0,0,0,0.3)',
                }}
              >
                {/* Логіка відображення логотипів */}
                <div style={{ marginBottom: '-4px' }}> {/* Зменшено відстань між лого та текстом */}
                  {filterRType === 'rotary' && (
                    <img
                      src="https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/RotaryMBS-Simple_CMYK-C.png"
                      alt={club.city}
                      height={30}
                    />
                  )}
                  {filterRType === 'rotaract' && (
                    <img
                      src="https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/rotaract_simple.png"
                      alt={club.city}
                      height={30}
                    />
                  )}
                  {!filterRType && (
                    <img
                      src="https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/1707638852340-506809438.png"
                      alt={club.city}
                      height={30}
                    />
                  )}
                </div>

                {/* Назва міста та кількість клубів */}
                <div>
                  {club.city} <br /> ({club.count} {getClubWord(club.count)})
                </div>
              </div>
            </Marker>

          ))
          }
      </MapGL>
    </Box>
  );
};

export default ClubsMapMapGL;
